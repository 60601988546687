import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0px;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px 16px 0px 16px;
  background-color: #f7c56d;
  width: 40%;
  @media (max-width: 450px) {
    width: 350px;
  }
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: black;
`;
const StyledTextNote = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: black;
`;

const StyledIconContainer = styled.div`
  padding: 12px;
  cursor: pointer;
`;

const StyledLoader = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 12px;
  align-self: flex-end;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ChatBoxFile({
  text,
  isFileUploading,
}: {
  text: string;
  isFileUploading: boolean;
}): JSX.Element {
  return (
    <StyledContainer>
      <StyledUserChatBox>
        <StyledIconContainer>
          <FontAwesomeIcon icon={faFileAlt} size="3x" color={"black"} />
        </StyledIconContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <StyledText>{text}</StyledText>
          <StyledTextNote>
            The context from this document can take upto 5 minutes to propagate
          </StyledTextNote>
        </div>
      </StyledUserChatBox>
      {isFileUploading && (
        <StyledLoader>
          <FontAwesomeIcon icon={faSpinner} size="1x" color={"black"} spin />
        </StyledLoader>
      )}
    </StyledContainer>
  );
}

export default ChatBoxFile;
