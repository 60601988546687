import React from "react";
import styled from "styled-components";
import DalbergValues1 from "theme/assets/images/dalberg-values-1.png";
import DalbergValues2 from "theme/assets/images/dalberg-values-2.png";
import DalbergValues3 from "theme/assets/images/dalberg-values-3.png";
import DalbergValues5 from "theme/assets/images/dalberg-values-5.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
`;

const StyledHeaderText = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  color: #4b5466;
  margin-top: 8px;
  text-align: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 16px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: column;
  max-width: 90%;
`;
const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 85%;
  justify-content: center;
  align-items: center;
`;
const StyledImage = styled.img`
  width: 100%;
  @media (max-width: 450px) {
    width: 350px;
  }
`;

const StyledImageRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

function Placeholder(): JSX.Element {
  return (
    <Container>
      <StyledWrapper>
        <StyledHeaderContainer>
          <StyledHeaderText
            style={{ fontSize: 28, fontWeight: 600, marginBottom: "8px" }}
          >
            Knowledge Hub
          </StyledHeaderText>
          <StyledHeaderText
            style={{ fontSize: 18, fontWeight: 500, marginBottom: "16px" }}
          >
            Our mission is to help build a more inclusive and sustainable world
            where all people, everywhere, can reach their fullest potential.
          </StyledHeaderText>
        </StyledHeaderContainer>
        <StyledImagesContainer>
          <StyledImageRowContainer>
            <LogoContainer>
              <StyledImage src={DalbergValues1} alt="CM" />
            </LogoContainer>
            <LogoContainer>
              <StyledImage src={DalbergValues2} alt="CM" />
            </LogoContainer>
          </StyledImageRowContainer>
          <StyledImageRowContainer>
            <LogoContainer>
              <StyledImage src={DalbergValues5} alt="CM" />
            </LogoContainer>
            <LogoContainer>
              <StyledImage src={DalbergValues3} alt="CM" />
            </LogoContainer>
          </StyledImageRowContainer>
        </StyledImagesContainer>
      </StyledWrapper>
    </Container>
  );
}

export default Placeholder;
